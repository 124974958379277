import React, { Fragment } from 'react';
import styledSystem from '@emotion/styled';
import { graphql } from 'gatsby';
import LayoutAuth from '../components/layoutAuth';

const Root = styledSystem.div`
  position: relative;
  top: 15vh;
  min-height: 65vh;
`;

const LandingPage = () => (
  <>
    <h1>Sign In or Register</h1>
  </>
);

export default () => (
  <Root>
    <LandingPage />
    <LayoutAuth />
  </Root>
);

export const pageQuery = graphql`
  query pageAuthContentQuery {
    placeholderImage: imageSharp(original: { src: { regex: "/placeholderImage/" } }) {
      fluid(maxWidth: 1700, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    heroImage: imageSharp(original: { src: { regex: "/pageAuthHero/" } }) {
      fluid(maxWidth: 1500, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    pageData: allPageDataJson(filter: { name: { eq: "pageAuth" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
